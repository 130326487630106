import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import styled from '@emotion/styled';
import * as showdown from 'showdown';
import { Globals } from '../styles/global';
import SEO from '../components/seo';
const converter = new showdown.Converter();

export const ContactPageTemplate = ({
  title,
  content,
  address,
}: {
  title: string;
  content: any;
  address: string[];
}) => {
  return (
    <div className="row">
      <SEO title={title} />
      <div className="contain-row">
        <div className="col-xs-12 col-sm-6">
          <div className="mt-u2">
            <h1>{title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
            />
          </div>
          {address.length > 0 && (
            <Address className="adress-holder">
              {address.map((addressLine, index) =>
                index !== 4 ? (
                  <span key={index}>{addressLine}</span>
                ) : (
                  <span key={index}>
                    <a href={`tel:${addressLine}`}>{addressLine}</a>
                  </span>
                ),
              )}
            </Address>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 py-u2">
          <IframeWrap>
            <iframe
              src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.7571800415253!2d-80.19946338461173!3d25.77858278362908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b6983630d193%3A0xe876182ed3fa6f55!2s401+NW+2nd+Ave+Tower+10th+Floor%2C+Miami%2C+FL+33128!5e0!3m2!1sen!2sus!4v1561811229840!5m2!1sen!2sus"
              frameBorder="0"
            ></iframe>
          </IframeWrap>
        </div>
      </div>
    </div>
  );
};

const ContactPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title = '', content = '' },
    },
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              address = '',
              address2 = '',
              citystate = '',
              zipcode = '',
              phone = '',
            },
          },
        },
      ],
    },
  },
}) => {
  return (
    <Layout>
      <ContactPageTemplate
        title={title}
        content={content}
        address={[address, address2, citystate, zipcode, phone]}
      />
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "footer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            address
            address2
            citystate
            zipcode
            phone
          }
        }
      }
    }
  }
`;

const Address = styled.address`
  span {
    display: block;
    font-style: normal;
  }
  a {
    color: ${Globals.grayscale_u2};
    &:hover: {
      text-decoration: none;
      color: ${Globals.grayscale_b};
    }
  }
`;

const IframeWrap = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
